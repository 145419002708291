import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import { Box } from 'theme-ui';
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import CauseWater4AllFeatures from 'components/CauseWater4AllFeatures';
import CauseWater4AllBuildYourOwnWell from 'components/CauseWater4AllBuildYourOwnWell';
import featureWater from 'images/feat-white-water.svg';
import featureMan from 'images/feat-white-man.svg';
export const pageQuery = graphql`
  query WaterForAllFR {
    heroImage: file(relativePath: {eq: "project-w4all-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ...WaterForAllBuildYourOwnWell
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Water For All: La meilleure des aumônes est de donner de l'eau (à boire)" description="Today, more than 600 million people do not have access to drinking water. Water is not only difficult to obtain, but often it is also contaminated: 80% of diseases in the world are related to the consumption of non-potable water or to poor sanitation, causing the death of millions of people every year around the world." mdxType="SEO" />
    <CauseHero purpose="water4all" title="Water For All" text={`"La meilleure des aumônes est de donner de l'eau (à boire)"`} footer="Prophète Muhammad ﷺ" data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <CauseWater4AllFeatures features={[{
      icon: featureWater,
      numbers: '200+',
      text: "Projets d'eau potable complétés"
    }, {
      icon: featureMan,
      numbers: '30,000+',
      text: 'Bénéficiaires'
    }]} mdxType="CauseWater4AllFeatures" />
    <Box className="p-wrapper" sx={{
      '&.p-wrapper': {
        marginBottom: [70,, 133]
      }
    }} mdxType="Box">
      <p>{`Aujourd'hui, plus de 600 millions de personnes n'ont pas accès à l'eau potable. Dans de nombreux pays, la désertification grandissante menace des populations entières. Cette eau n'est pas seulement difficile à obtenir, elle est souvent également contaminée: jusqu'à 80% des maladies sont liées la consommation d'eau non-potable et aux mauvaises conditions sanitaires, causant tous les ans la mort de millions de personnes à travers le monde.`}</p>
      <p>{`Depuis la fondation d'Ummaty, Water4all est l'un des projets dont nous sommes les plus fiers. Nous travaillons tous les jours pour fournir de l'eau potable aux populations à travers le monde. Alhamdoulillah, nous réalisons des dizaines de projets d'eau chaque année et avons développé un savoir faire important dans ce domaine. Nos projets d'eau sont de nature différente: des simples puits de surface aux forages de puits profonds et lorsque cela est possible, nous fournissons l'infrastructure complète, y compris les pompes, les réservoirs d'eau et les canalisations afin de fournir de l'eau potable directement aux maisons, aux écoles et aux autres installations.`}</p>
    </Box>
    <CauseWater4AllBuildYourOwnWell data={props.data} title="Construisez votre propre puits" subtitle="Pour vous-même ou pour un être cher" content={`
  <ul>
    <li>
      Ummaty vous offre également la possibilité de creuser votre propre puits de surface et d'obtenir <b>toutes les récompenses pour cette sadaqa jariyah</b> chaque fois qu'un enfant, une femme ou un homme de ces communautés boira de cette eau.
    </li>
    <li>
      Pour un coût à partir de <b>$180 USD</b>, nous creuserons un puits de surface, installerons une pompe et y mettrons une plaque à votre nom. Vous pouvez également nommer le puits et dédier cette sadaqa à une personne en particulier chère à votre coeur.
    </li>
    <li>
      Après l'achèvement du projet, l'équipe d'Ummaty vous fournira un <b>rapport détaillé</b>, comprenant des informations sur le puits, des <b>photos</b> et des liens <b>vidéo de l'inauguration du puits</b>.
    </li>
    <li>
      Water4all construisez votre propre puits: <b>le meilleur cadeau</b> que vous puissiez vous offrir à vous même ou à vos proches, qu'ils soient vivants ou décédés.
    </li>
  </ul>
  `} buttonText="Creuser mon propre puits" mdxType="CauseWater4AllBuildYourOwnWell" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      